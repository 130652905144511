import { SVGProps } from 'react';

const SvgSportsBasketball = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M96.4 188.9v.2h8.3v-.2c20.1-.9 38.5-8.4 53-20.6 0 0 5.1-4.5 5.9-5.3 15.2-15.2 24.8-35.9 25.9-58.9h.2v-8.3h-.2c-1.1-24.2-11.8-45.8-28.4-61.1 0-.1.1-.1.1-.1l-6.3-5.4s-.1.1-.1.2c-14-10.8-31.2-17.4-50-18.3v-.2h-8.3v.2C78 12 60.9 18.4 47 28.9c0 0-4.4 3.1-4.2 3.3-1.8 1.5-3.5 3.1-5.2 4.7-15.3 15.3-25 36-26 59h-.2v8.3h.2c2.1 45.8 38.9 82.6 84.8 84.7zm-76.5-84.8h34.9c-.5 31.3-6.2 47.7-9.6 54.7C30.5 145 21 125.7 19.9 104.1zm31.8 60.2c3.9-7.3 10.8-25.1 11.3-60.2h33.3v76.5c-16.7-.8-32-6.8-44.6-16.3zm52.9-60.2h36.2c.7 30.9 6.3 49.7 10.1 59-13.2 10.6-29.3 16.6-46.3 17.5v-76.5zm53.1 53.1c-.1 0-.1 0 0 0-3.5-9.2-7.9-26.3-8.5-53h32c-1.1 20-9.3 38.7-23.5 53zm23.4-61.3H149c.2-28.3 4.6-44.9 8.1-53.5 14 13.8 23 32.6 24 53.5zm-30.5-59.3c-4.1 9-9.6 27.1-9.8 59.2h-36.1V19.4c17.2.9 33.1 7.1 45.9 17.2zM96.4 95.9H63.1c-.5-32.2-6.2-51.6-10.1-61.2 12.6-9.2 27.6-14.5 43.4-15.3v76.5zM43.3 42.8c.9-.9 1.9-1.7 2.9-2.6 3.5 9.4 8.1 27.3 8.6 55.6H19.9c1-20 9.2-38.7 23.4-53z"
      style={{
        fill: '#a1b1c8',
      }}
    />
  </svg>
);

export default SvgSportsBasketball;
