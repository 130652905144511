import { SVGProps } from 'react';

const SvgSportsFootball = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="sports-football_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.sports-football_svg__st0{fill:#a1b1c8;transform:rotate(90deg);transform-origin:center}'
      }
    </style>
    <path className="sports-football_svg__st0" d="M97.3 58.7h5.9V140h-5.9z" />
    <path
      className="sports-football_svg__st0"
      d="M86.5 67.6h28v5.9h-28zM86.5 86.9h28v5.9h-28zM86.5 106.2h28v5.9h-28zM86.5 125.5h28v5.9h-28z"
    />
    <path
      className="sports-football_svg__st0"
      d="M122.9 179.9c-4.3 4.3-15.1 14.3-22.4 14.3-7.5 0-19.3-10.4-27.9-20.2-2.7-3-12.1-15.9-12.5-16.4-8.8-13.6-19.3-34.5-19.3-57.6s9.4-43.5 17.4-56.5c.3-.5 6.9-11.1 12-16.7 2-2.4 4.1-4.7 6.2-6.8C81.7 14.7 92 5.8 100.5 5.8c8.4 0 20.8 10.9 29.5 21.1.2.2 6.8 7.2 12.2 16.7 8.2 13.1 17.9 33.6 17.9 56.4 0 23.1-10.5 44.1-19.2 57.5-2.7 4.3-12.1 16-12.5 16.4-1.9 2.2-3.7 4.2-5.5 6zM80.5 24.2c-2 2-4 4.1-5.9 6.5-4.8 5.3-11.3 15.8-11.4 15.9-7.5 12.3-16.5 31.7-16.5 53.4s9.9 41.5 18.3 54.3c2.6 3.5 9.9 13.5 12 15.8 10.4 11.8 19.9 18.2 23.5 18.2 2.7 0 9.5-3.9 18.2-12.6 1.7-1.7 3.5-3.6 5.2-5.6 0 0 9.5-11.8 12-15.8 8.4-12.8 18.3-32.7 18.3-54.4 0-21.5-9.3-40.9-17.1-53.4-5.1-9-11.4-15.7-11.5-15.7-11.3-13.2-20.9-19.2-25.2-19.2-3.5.1-10.6 3.4-19.9 12.6z"
    />
    <path
      className="sports-football_svg__st0"
      d="M97.9 159.3h5.3v30.9h-5.3zM97.9 6.5h5.3v30.9h-5.3z"
    />
  </svg>
);

export default SvgSportsFootball;
