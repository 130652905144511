import { SVGProps } from 'react';

const SvgSportsTennis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M187 76.2c-.1-.2-.1-.4-.1-.6-10.6-37.7-45.3-65.5-86.4-65.5-12.9 0-25.1 2.8-36.2 7.7-2.8 1.2-5.5 2.6-8.2 4.2C29 37.5 10.7 66.6 10.7 100c0 16.8 4.7 32.4 12.7 45.8 1.4 2.4 2.9 4.7 4.5 6.9 16.3 22.4 42.7 37.1 72.5 37.1 49.5 0 89.8-40.3 89.8-89.8.1-8.2-1.1-16.2-3.2-23.8zM30.3 141.3c-7.2-12.1-11.4-26.2-11.4-41.3 0-27.9 14.1-52.5 35.5-67.2-.1 1.6-.2 3.1-.2 4.7 0 1.3.1 3.4.2 6.2.6 14.7 2.3 53.8-3 67.3-4.9 12.4-10.6 22.3-21.1 30.3zm4.6 6.9c12.2-9.1 18.8-20.5 24.3-34.2 5.3-13.4 4.8-43.2 3.6-70.7-.1-2.7-.2-4.7-.2-6-.1-3.1.4-6.6 1-9.8 11.1-5.7 23.7-9 37.1-9 36.1 0 66.8 23.6 77.5 56.2-21.3 30.6-43.7 47.3-66.6 62.9-19 12.9-24.9 32.1-26.7 42.4-20.6-4.1-38.2-15.7-50-31.8zm65.6 33.4c-2.6 0-5.2-.2-7.7-.4 1.7-9 6.8-25.7 23.2-36.8 21.3-14.4 43.6-31 64.6-59.2.9 4.8 1.4 9.8 1.4 14.9.1 44.9-36.5 81.5-81.5 81.5z"
      style={{
        fill: '#a1b1c8',
      }}
    />
  </svg>
);

export default SvgSportsTennis;
