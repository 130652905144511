import { SVGProps } from 'react';

const SvgSportsTrack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="sports-track_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.sports-track_svg__st0{fill:none;stroke:#a1b1c8;stroke-width:4.8571;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}'
      }
    </style>
    <path
      className="sports-track_svg__st0"
      d="M11.2 102.3h20.6M24 113.2h35.5M11.2 126.4h61.9M91.2 34.4S62.7 44.7 59.4 74.8L50 84.3l80.8 76.3h59v-14.4s-.8-25.6-36.7-25.6l-21-54.1c-.1 0-34.3 8.3-40.9-32.1z"
    />
    <path
      className="sports-track_svg__st0"
      d="m59.5 74.8 77.9 71.4h52.4M134.9 115.2l14.9-3.1M128.3 100.9l14.8-3.1M122.4 87.8l14.8-3.1"
    />
  </svg>
);

export default SvgSportsTrack;
