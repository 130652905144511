import { SVGProps } from 'react';

const SvgSportsBaseball = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="sports-baseball_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.sports-baseball_svg__st0{fill:#a1b1c8}'}</style>
    <path
      className="sports-baseball_svg__st0"
      d="M100.5 190.5C50.6 190.5 10 149.9 10 100S50.6 9.5 100.5 9.5 191 50.1 191 100s-40.6 90.5-90.5 90.5zm0-173.2c-45.6 0-82.7 37.1-82.7 82.7s37.1 82.7 82.7 82.7 82.7-37.1 82.7-82.7-37.1-82.7-82.7-82.7z"
    />
    <path
      className="sports-baseball_svg__st0"
      d="M45.3 45.3c-1.8-4.1-3.1-6.4-3.2-6.5L52.4 33s1.6 2.7 3.7 7.6l-10.8 4.7zM64.9 145l-11.4-3c1.4-5.3 2.5-10.7 3.3-16.1l11.7 1.7c-.9 5.8-2.1 11.6-3.6 17.4zm5-35.2-11.8-.3v-3.6c0-4.3-.2-8.6-.6-12.9l11.8-1c.4 4.6.6 9.3.6 13.9v3.9zM55.2 76.7c-1.1-5.4-2.5-10.8-4.1-16l11.3-3.5c1.8 5.6 3.2 11.4 4.4 17.2l-11.6 2.3zM56.1 169.5l-10.7-4.9c1.1-2.3 2-4.7 3-7l11 4.3c-1.1 2.6-2.2 5.1-3.3 7.6zM147.8 170s-1.6-2.7-3.7-7.6l10.8-4.8c1.8 4.1 3.1 6.4 3.2 6.5l-10.3 5.9zM137.8 145.8c-1.8-5.6-3.2-11.4-4.4-17.2l11.6-2.3c1.1 5.4 2.4 10.7 4.1 16l-11.3 3.5zm-6.9-34.8c-.4-4.7-.6-9.3-.6-13.9v-3.9l11.8.3v3.6c0 4.2.2 8.6.6 12.9l-11.8 1zm12.6-33.9-11.7-1.7c.8-5.9 2-11.7 3.5-17.5l11.4 3c-1.4 5.4-2.5 10.8-3.2 16.2zM151.9 45.3l-11-4.3c1-2.5 2-5 3.2-7.5l10.7 4.9c-1 2.3-2 4.6-2.9 6.9z"
    />
  </svg>
);

export default SvgSportsBaseball;
