import {
  On3IconSportsBaseball,
  On3IconSportsBasketball,
  On3IconSportsFootball,
  On3IconSportsGolf,
  On3IconSportsGymnastics,
  On3IconSportsHockey,
  On3IconSportsLacrosse,
  On3IconSportsSoccer,
  On3IconSportsSwimming,
  On3IconSportsTennis,
  On3IconSportsTrack,
  On3IconSportsVolleyball,
} from '@on3/icons';
import { SVGProps } from 'react';

const sportList = [
  'Baseball',
  'Basketball',
  'Football',
  'Golf',
  'Gymnastics',
  'Lacrosse',
  'Soccer',
  'Softball',
  'Tennis',
  'Track',
  'Volleyball',
  'Hockey',
  'Swimming',
] as const;

export type SportName = (typeof sportList)[number];

const sportIconDictionary: {
  [key in SportName]: (props: SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  Baseball: On3IconSportsBaseball,
  Basketball: On3IconSportsBasketball,
  Football: On3IconSportsFootball,
  Golf: On3IconSportsGolf,
  Gymnastics: On3IconSportsGymnastics,
  Lacrosse: On3IconSportsLacrosse,
  Soccer: On3IconSportsSoccer,
  Softball: On3IconSportsBaseball,
  Tennis: On3IconSportsTennis,
  Track: On3IconSportsTrack,
  Volleyball: On3IconSportsVolleyball,
  Hockey: On3IconSportsHockey,
  Swimming: On3IconSportsSwimming,
};

/* Response from api.
 * I believe `key: 3` should be `Men's Track`
  { key: 1, name: 'Football' },
  { key: 2, name: 'Basketball' },
  { key: 4, name: 'Baseball' },
  { key: 5, name: "Women's Basketball" },
  { key: 6, name: 'Volleyball' },
  { key: 7, name: 'Gymnastics' },
  { key: 8, name: "Men's Lacrosse" },
  { key: 9, name: "Women's Lacrosse" },
  { key: 10, name: "Men's Soccer" },
  { key: 11, name: "Women's Soccer" },
  { key: 12, name: 'Softball' },
  { key: 13, name: "Women's Track" },
  { key: 14, name: "Men's Golf" },
  { key: 15, name: "Women's Golf" }
  { key: 16, name: "Wen's Hockey" }
  { key: 17, name: "Women's Hockey" }
  { key: 18, name: "Men's Swimming" }
  { key: 19, name: "Women's Swimming" }
  */
const sportKeyIconDictionary: {
  [key: number]: (props: SVGProps<SVGSVGElement>) => JSX.Element;
} = {
  1: On3IconSportsFootball,
  2: On3IconSportsBasketball,
  3: On3IconSportsTrack,
  4: On3IconSportsBaseball,
  5: On3IconSportsBasketball,
  6: On3IconSportsVolleyball,
  7: On3IconSportsGymnastics,
  8: On3IconSportsLacrosse,
  9: On3IconSportsLacrosse,
  10: On3IconSportsSoccer,
  11: On3IconSportsSoccer,
  12: On3IconSportsBaseball,
  13: On3IconSportsTrack,
  14: On3IconSportsGolf,
  15: On3IconSportsGolf,
  16: On3IconSportsHockey,
  17: On3IconSportsHockey,
  18: On3IconSportsSwimming,
  19: On3IconSportsSwimming,
};

interface SportIconProps extends SVGProps<SVGSVGElement> {
  sportName: string | number | '' | undefined | null;
}

export const SportIcon = ({ sportName = '', ...rest }: SportIconProps) => {
  // if sport name is falsey, or if sport name is provided,
  // but we don't have an icon for it yet then return the default icon
  if (!sportName) {
    return null;
  }

  if (typeof sportName === 'number') {
    const Icon = sportKeyIconDictionary[sportName];

    return <Icon {...rest} />;
  }

  const sportNameSplit = sportName.split(' ');
  const sportKey = sportNameSplit[sportNameSplit.length - 1] as SportName;
  const Icon = sportIconDictionary[sportKey];

  return Icon ? <Icon {...rest} /> : null;
};
