import { SVGProps } from 'react';

const SvgSportsHockey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="sports-hockey_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.sports-hockey_svg__st0{fill:#a1b1c8}'}</style>
    <path
      className="sports-hockey_svg__st0"
      d="M15 192.8c-3.3 0-4.9-1.5-5.7-2.8-1.8-3-.9-7.6 2.8-14.5.6-2 4.1-11.4 15.3-13.5l29.8-6.6c.7-.2 1.3-.7 1.6-1.4l1.3-3.7 22-4.9-11.4 31.7-.5.5c-.2.2-.4.4-.5.6-1.6 1.8-3.4 3.4-10.6 4.7-.4.1-.8.2-1.3.3-.5.2-1.1.3-1.7.5l-35 7.7c.1.1-3.1 1.4-6.1 1.4zm49.2-37.7-.3.8c-.9 2.5-3 4.3-5.6 4.9l-29.9 6.6c-8.9 1.6-11.1 9.5-11.2 9.8l-.2.6c-4.1 7.5-3.1 9.3-3.1 9.3s.3.2 1 .2c1.6 0 3.7-.7 4.4-1l35.5-7.9c.5-.1.9-.2 1.3-.4.6-.2 1.2-.4 1.9-.5 5.9-1.1 6.7-2 7.4-2.8.2-.2.3-.4.5-.6l7.6-21.1-9.3 2.1zM61.9 143l3.4-9.4 22.1-4.9-3.4 9.4zM186 192.8c-3 0-6.2-1.3-6.5-1.5l-34.5-7.6c-.6-.1-1.2-.3-1.7-.5-.4-.1-.8-.3-1.3-.3-7.2-1.3-9-2.8-10.6-4.7-.2-.2-.3-.4-.5-.6l-.5-.5-11.4-31.7 22 4.9 1.3 3.7c.2.7.8 1.2 1.6 1.4l29.9 6.6c11.2 2.1 14.6 11.4 15.2 13.5 3.7 6.9 4.6 11.5 2.8 14.5-.8 1.2-2.5 2.8-5.8 2.8zm-51-18.7c.2.2.3.4.5.6.7.8 1.5 1.8 7.4 2.8.6.1 1.2.3 1.9.5.4.1.8.3 1.3.4l35 7.7c1.2.4 3.3 1.2 4.9 1.2.6 0 .9-.1 1-.2 0 0 1-1.8-3.1-9.3l-.2-.6c-.1-.3-2.3-8.1-11.1-9.8l-30-6.6c-2.6-.6-4.7-2.4-5.5-4.9l-.3-.8-9.3-2.1 7.5 21.1zM139.1 143l-22.1-4.9-3.4-9.4 22.1 4.9zM132.3 125.1l-22.2-4.9-9.6-23.4-9.6 23.4-22.2 4.9 21.6-53.2-20.8-50.6c-.9-2.5-.8-5.1.3-7.4s3-4.1 5.4-4.9c4.8-1.7 10.5.9 12.2 5.7l13.1 32.1 13.1-32.2c1.7-4.7 7.3-7.4 12.2-5.6 2.4.9 4.3 2.6 5.4 4.9 1.1 2.3 1.2 4.9.3 7.3l-20.8 50.7 21.6 53.2zm-18.2-9.7 9.2 2-18.5-45.6 21.6-52.7c.3-.9.3-2-.2-3s-1.3-1.7-2.3-2.1c-2.1-.7-4.4.4-5.2 2.4l-18.2 44.8-18.2-44.5c-.8-2.2-3.2-3.3-5.2-2.5-1 .4-1.8 1.1-2.3 2.1-.5 1-.5 2.1-.1 3.1L96.3 72l-18.5 45.6 9.2-2 13.6-33.2 13.5 33z"
    />
  </svg>
);

export default SvgSportsHockey;
