import { SVGProps } from 'react';

const SvgSportsVolleyball = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M192.1 100c0-50.5-41.1-91.6-91.6-91.6S8.9 49.5 8.9 100c0 33.1 17.7 62.1 44.1 78.2.7.4 1.4.9 2.1 1.3 13.4 7.7 28.9 12.2 45.5 12.2 45.1 0 82.6-32.8 90.1-75.7 0-.2.1-.5.1-.8.8-5 1.3-10 1.3-15.2zm-85.3 84.1c-2.2-5.5-7.2-19.6-9.4-36.8 39 5.2 69.3-12.5 84-23.6-9.6 33-39.1 57.7-74.6 60.4zM63.4 69.2C75.5 75 88 83.4 96.4 95.4c-15.1 34.9-2.6 74.9 2.8 88.9-14.6-.2-28.3-4.2-40.2-11-4.4-12.2-16.1-54.2 4.4-104.1zM27.6 57.8c4.8.9 16.4 3.3 29.2 8.5-18.2 44.1-12.6 81.7-7.5 100.6-20.1-15.4-33.2-39.7-33.2-66.9 0-15.4 4.2-29.8 11.5-42.2zm46.8-38c7.2 2.1 52.6 17 67.1 60.7-16.1 8.2-33.4 10.2-39.2 10.7C83.1 63.7 46 54 31.8 51.2c10.4-14.6 25.3-25.8 42.6-31.4zm96 33 .1-2.6c-4.7 11-13.7 21-22.6 26.6-12.5-35.8-43.1-53-60.4-60.1 4.3-.7 8.6-1.1 13-1.1 29 0 54.7 14.8 69.9 37.2zm14.5 47.2c0 4.2-.4 8.3-1 12.3-8.4 7.5-42.4 34.4-87.1 27.6-.9-13.4.4-28 6.2-41.5 10.6-.8 54-6 71.2-39.4 6.8 12.1 10.7 26.1 10.7 41z"
      style={{
        fill: '#a1b1c8',
      }}
    />
  </svg>
);

export default SvgSportsVolleyball;
