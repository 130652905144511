import { SVGProps } from 'react';

const SvgSportsSoccer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M100.5 7.3C50.2 7.3 9.2 48.9 9.2 100s40.9 92.7 91.3 92.7 91.3-41.6 91.3-92.7-41-92.7-91.3-92.7zm69 139.8L144 152l-13.5-17.8 13-42 18.6-7.4 17 18.2 4.5-2c-.2 17-5.3 32.9-14.1 46.1zm-110.9 5.1-26-3.5c-9.2-13.2-14.7-29.2-15.2-46.5l5.6 1.9 16.4-20 18 8.1 14.2 42.1-13 17.9zM33.7 57l.5 20.6-13.9 17-2.6-.9c1-13.9 5.3-26.8 12.2-38l3.8 1.3zm45.4 73.9L65.6 90.7l34.9-25.4 35 25.5-12.5 40.1H79.1zm91.3-76.5c7.1 11.1 11.6 24 12.9 37.9l-2.2 1-13.9-14.9-.9-22.1 4.1-1.9zm-4.8-6.8L158 51l1 26.2-18.2 7.3-36.3-26.4V36.5l24.2-9.6v-6.2c14.7 5.3 27.4 14.7 36.9 26.9zm-45-29.5v3.2l-20.2 8.1-19-6.9v-4.6c6.1-1.5 12.5-2.3 19.1-2.3 6.9-.1 13.7.8 20.1 2.5zm-47.3 2.1v7.9l23.1 8.4v21.6L60.3 84.5l-18-8.1-.6-25.3-7.2-2.4c9.9-13 23.3-23 38.8-28.5zM40.1 157.9l17.9 2.4 10.3 17.6c-10.8-4.6-20.3-11.5-28.2-20zM80 181.8l-14.6-25L78.2 139H124l13.5 17.8-13.4 24.2c-7.5 2.3-15.4 3.5-23.6 3.5-7.1 0-14-1-20.5-2.7zm56-5.5 9-16.3 16.9-3.2c-7.3 8.1-16.1 14.8-25.9 19.5z"
      style={{
        fill: '#a1b1c8',
      }}
    />
  </svg>
);

export default SvgSportsSoccer;
