import { SVGProps } from 'react';

const SvgSportsLacrosse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="sports-lacrosse_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.sports-lacrosse_svg__st0{fill:none;stroke:#a1b1c8;stroke-width:4.634;stroke-miterlimit:10}'
      }
    </style>
    <path
      className="sports-lacrosse_svg__st0"
      d="M113.4 89.8s6-10.9 6.3-16.1c0 0 .7-4.2 10.2-14.4 0 0 9.1-10.2 9.8-17.9 0 0 .7-20.7 17.6-21.1 0 0 14.4 2.1 21.1 9.5 0 0 14.4 12.6 11.9 25.3 0 0 .4 8.8-10.5 13.3 0 0-20.7 3.9-23.2 7.7 0 0-12.6 11.9-17.2 13.7 0 0-13.7 2.8-18.6 6.7l-76.9 74.8s3.9 3.5 0 7.7c0 0-2.5 3.9-7.4 0l-8.4-8.4s-1.4-3.2 2.1-6.3c0 0 3.7-1.8 6.5.5l76.7-75z"
    />
    <path
      className="sports-lacrosse_svg__st0"
      d="M118.4 78.9s5.6 9.3 14 12.7M122.6 68.2s4.2 14.6 21 19M151.1 81.5v-60M164.5 71.5V23.8M177.8 66.8V31.7M137.8 84.4V46.7M141.4 32.9h38.9M138.2 46.7h51.3M129.9 59.1h59.6M125.4 72.1h39.1M112 93.8l4 4.3M106.8 109.7l54.6 56.1s3.5-3.9 7.7 0c0 0 3.9 2.5 0 7.4l-8.4 8.4s-3.2 1.4-6.3-2.1c0 0-1.8-3.7.5-6.5l-54.5-55.8M93 109.7 80 96.3s-10.9-6-16.1-6.3c0 0-4.2-.7-14.4-10.2 0 0-10.2-9.1-17.9-9.8 0 0-20.7-.7-21.1-17.6 0 0 2.1-14.4 9.5-21.1 0 0 12.6-14.4 25.3-11.9 0 0 8.8-.4 13.3 10.5 0 0 3.9 20.7 7.7 23.2 0 0 11.9 12.6 13.7 17.2 0 0 2.8 13.7 6.7 18.6l12.8 13.2"
    />
    <path
      className="sports-lacrosse_svg__st0"
      d="M69 91.3s9.3-5.6 12.7-14M58.4 87.1s14.6-4.2 19-21M71.6 58.5H11.7M61.6 45.2H14M57 31.9H21.9M74.6 71.9H36.9M23.1 68.3V29.4M36.9 71.5V20.2M49.2 79.8V20.2M62.2 84.3V45.2M84 97.7l4.2-4"
    />
  </svg>
);

export default SvgSportsLacrosse;
