import { SVGProps } from 'react';

const SvgSportsSwimming = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="sports-swimming_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.sports-swimming_svg__st0{fill:none;stroke:#a1b1c8;stroke-width:3.7292;stroke-miterlimit:10}'
      }
    </style>
    <path
      className="sports-swimming_svg__st0"
      d="M121.9 102.5c-10.4 0-18.8-8.4-18.8-18.8s8.4-18.8 18.8-18.8 18.8 8.4 18.8 18.8-8.4 18.8-18.8 18.8zM8.3 117.4s48.9 35.7 87.2 9.4c34.2-23.5 55.1-16.4 91.4-5.4"
    />
    <path
      className="sports-swimming_svg__st0"
      d="M105.4 110.8 78.9 62.4s-4.2-5.5-15.2 0-36.3 16-36.3 16-4.1 2.3-2.7 5.3c.5 1.1 2.1 2.6 5.7 1.7l35-9.5s4.5-1.5 5.8 2l5.5 12.3-46.4 31.2"
    />
  </svg>
);

export default SvgSportsSwimming;
