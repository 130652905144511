import { SVGProps } from 'react';

const SvgSportsGolf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="sports-golf_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>{'.sports-golf_svg__st0{fill:#a1b1c8}'}</style>
    <path
      className="sports-golf_svg__st0"
      d="m120.6 131-12.6 3.2c-4.7 1.2-9.5 1.2-14.2 0L81.1 131 92 150l3.4 23.6 5.5 16.6 5.6-17.2 3.2-23.1 10.9-18.9zM100.5 113.3c-28.5 0-51.8-23.2-51.8-51.8S72 9.7 100.5 9.7s51.8 23.2 51.8 51.8-23.3 51.8-51.8 51.8zm0-95.3C76.5 18 57 37.5 57 61.5S76.5 105 100.5 105 144 85.5 144 61.5 124.5 18 100.5 18z"
    />
  </svg>
);

export default SvgSportsGolf;
