import { SVGProps } from 'react';

const SvgSportsGymnastics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="sports-gymnastics_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 200 200"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        '.sports-gymnastics_svg__st0,.sports-gymnastics_svg__st1{fill:none;stroke:#a1b1c8;stroke-width:4.811;stroke-miterlimit:10}.sports-gymnastics_svg__st1{stroke-width:9.6221}'
      }
    </style>
    <path className="sports-gymnastics_svg__st0" d="M19 28h163" />
    <path className="sports-gymnastics_svg__st1" d="M8.2 172.5h184.6" />
    <path className="sports-gymnastics_svg__st0" d="M28.8 28v27.8" />
    <path className="sports-gymnastics_svg__st1" d="M28.8 62.5v110" />
    <path className="sports-gymnastics_svg__st0" d="M172.6 28v27.8" />
    <path className="sports-gymnastics_svg__st1" d="M172.6 62.5v110" />
    <path className="sports-gymnastics_svg__st0" d="M52.8 76.7v26.8" />
    <path className="sports-gymnastics_svg__st1" d="M52.8 111.6v60.9" />
    <path className="sports-gymnastics_svg__st0" d="M148.9 76.7v26.8" />
    <path className="sports-gymnastics_svg__st1" d="M148.9 111.6v60.9" />
    <path className="sports-gymnastics_svg__st0" d="M44.3 76.7h114.1" />
  </svg>
);

export default SvgSportsGymnastics;
